export default {
  shoutSectionV3: {
    container: { flexWrap: ['wrap', '', '', 'nowrap'] },
    title: {
      backgroundColor: 'primary',
      padding: '0.5rem',
      color: 'light',
      flexGrow: '1',
      display: 'flex',
      alignItems: 'center',
      order: ['', '', '1']
    },
    text: {
      fontSize: '1.5rem',
      padding: '1rem 0.5rem',
      order: ['', '', '4'],
      flexGrow: '1',
      display: 'flex',
      alignItems: 'center',
      backgroundColor: 'secondary',
      width: ['', '', '70%'],
      fontWeight: 'bold',
      textDecoration: 'uppercase'
    },
    date: {
      backgroundColor: 'secondary',
      padding: '0.5rem',
      color: 'light',
      maxWidth: '130px',
      display: 'flex',
      alignItems: 'center',
      order: ['', '', '2']
    },
    imageContainer: {
      position: 'relative',
      width: ['100%', '100%', '20%'],
      order: ['', '', '3'],
      img: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        objectFit: 'cover'
      }
    },
    imageFill: {
      paddingBottom: '70%'
    },
    shoutCTAS: {
      flexWrap: 'wrap',
      transition: 'all ease-in-out 0.3s',
      width: ['100%', '', 'auto'],
      order: ['', '', '5']
    },
    shoutCTA: {
      variant: 'buttons.primary',
      fontSize: ['1rem', '1rem'],
      transition: 'all ease-in-out 0.3s',
      width: '50%',
      flexGrow: '1',
      backgroundColor: 'primary',
      color: 'light',
      textAlign: 'center',
      writingMode: ['', '', 'tb'],
      // border: 'solid 1px white',
      padding: '0.5rem',
      display: 'flex',
      alignItems: 'center',
      ':hover': {
        color: 'secondary'
      }
    },

    // ? ===========================
    // ? === Shout Pop Up Styles ===
    // ? ===========================

    shoutPopUpButton: {
      position: ['fixed'],
      left: ['0rem', '', '0.25rem'],
      bottom: ['4rem', '', '0.25rem'],
      zIndex: '1200',
      borderRadius: ['0px', '', '', '1000px'],
      padding: ['0.25rem', '', '0.5rem'],
      width: ['fit-content', '', '4rem'],
      height: ['2.25rem', '', '4rem'],
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      writingMode: 'tb',
      transform: ['rotate(90deg) translate(-100%, -100%)', '', 'unset'],
      transformOrigin: ['left top', '', 'initial'],
      fontSize: '0.8rem',
      svg: {
        width: '2rem',
        height: '2rem',
        fill: 'none',
        color: 'inherit',
        marginRight: ['0.5rem', '', '0rem']
      }
    },

    shoutPopUpButtonText: {
      display: ['', '', 'none']
    },

    containerPopUp: {
      display: 'flex',
      flexDirection: ['column', '', 'row'],
      position: 'fixed',
      left: ['2rem', '', '3.5rem'],
      bottom: ['5rem', '', '3.5rem'],
      zIndex: '1000',
      maxWidth: '767px',
      backgroundColor: 'background',
      color: 'text',
      padding: ['1rem'],
      // boxShadow: '2px 2px 10px grey',
      border: 'solid 1px',
      borderColor: 'lightgrey',
      width: ['90%', '', '70%'],
      maxHeight: 'calc(90vh - 5rem)',
      overflowY: 'scroll',
      '::-webkit-scrollbar': {
        width: '2px',
        display: 'none'
      },
      '-ms-overflow-style': 'none' /* IE and Edge */,
      scrollbarWidth: 'none',

      '::-webkit-scrollbar': {
        display: 'none'
      }
    },

    imageContainerPopUp: {
      width: ['100%', '', '25%'],
      img: {
        objectFit: 'cover',
        maxHeight: '40vh',
        width: '100%'
      }
    },

    shoutContentContainerPopUp: {
      width: ['100%', '', '75%'],
      padding: ['1rem 0rem 0rem', '', '1rem'],
      flexGrow: '1'
    },

    titlePopUp: {
      variant: 'customVariants.title',
      fontSize: ['1rem', '1.2rem', '1.4rem', '1.6rem', '1.8rem'],
      marginBottom: '1rem'
    },
    datePopUp: {
      variant: 'customVariants.text',
      marginBottom: '1rem',
      fontSize: ['0.8rem', '0.8rem', '0.9rem']
    },
    textPopUp: {
      variant: 'customVariants.title',
      fontSize: ['1rem', '1.2rem', '1.4rem', '1.6rem', '1.8rem']
    },
    shoutCTASPopUp: {
      marginTop: '1rem'
    },
    shoutCTAPopUp: {
      variant: 'buttons.primary',
      margin: '0rem 0.5rem 0.5rem 0rem',
      padding: '0.5rem'
    }
  }
}
